:root{
    --darkBlue: #5179fa;
    --lightBlue: #b5c6ff;
}

.grid-item{
    padding:1rem 2rem 1rem 2rem;
    span{
        font-size: 80px;
    }
}
.grid-container{
    display: grid;
    grid-template-columns: repeat(2,1fr);
}
.grid-container{
    background-color: #a6aff8;
    border-radius: 20px;
}

.room-select{
    margin-top:1.5rem;
    background:"#7e9cff";
    width:300px;
    height:50px;
    border-radius:"10px";
    text-align: center;
}
.grid-item span{
    font-size: 70px;
}