.show-bill-page{
    padding: 1rem;
}
.Bill-Container{
    border: 2px solid black;
    margin: 2.5rem;
    padding: 0.5rem;
    width: 95vw;
}
.roomNumber{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #000;
    padding:0.3rem;
    border-bottom: 1px solid #000;
}
.list-item{
    margin: 0.5rem 0 0.5rem 2rem;
    div{
        display: flex;
        justify-content: space-between;
        padding: 0.5rem
    }
}
.list-container{
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}
.body{
    background-color:#fff ;
}
.bill-body{
    background-color: #fff;
}
