*{
  margin: 0;
  padding: 0;
  box-sizing:border-box;
}
body{
  background: #f9f9f9;
}
.app{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
