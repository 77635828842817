.monthly-app-container{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #b0b0b0;
}

.payment-state{
    display: flex;
    padding: 0.5rem;
}
.payment-state h3{
    margin-right: 0.3rem;
}
.payment-state div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.month-col{
    display: flex;
    align-items: center;
    padding: 0 0.5rem 0 0.5rem;
}
.payment-state button{
    width: max-content;
    margin-left: 0.5rem;
}